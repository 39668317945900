import React, {Component} from 'react';
import { Routes, Route } from "react-router-dom";
import classes from './App.module.scss';
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./hoc/Home/Home";
import Gallery from "./hoc/Gallery/Gallery";
import Thanks from "./hoc/Thanks/Thanks";


const quoteFrame=(
  <>
    <iframe
      id="JotFormIFrame-231763676513158"
      title="Sweet Home Website Contact/Quote Form"
      onload="window.parent.scrollTo(0,0)"
      allowtransparency="true"
      allow="geolocation; microphone; camera"
      src="https://form.jotform.com/231763676513158"
    >
    </iframe>
  </>
)


class App extends Component {
  render() {
    let routes = (
      <Routes>
        <Route exact={true} path="/" element={<Home />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/thanks" element={<Thanks />} />
      </Routes>
    );
    return (
      <div className={[classes.shcaWrap, classes.fullSec].join(' ')}>
        <Header />
        <main className={classes.mainWrap}>
          {routes}
        </main>
        <ScrollUpButton
          ContainerClassName={classes.ToTopBtn}
          TransitionClassName="MyOverRideTransitionedClass"
          EasingType='linear'
        />
        <Footer />

        <div id="quote-modal" className={[classes.modalWrap, classes.quoteModal].join(' ')} data-uk-modal>
          <div className={[classes.modalBody, "uk-modal-dialog uk-modal-body"].join(' ')}>
            <button className={[classes.closeBtn, "uk-modal-close-default"].join(' ')} type="button" data-uk-close></button>

            <div className={[classes.row, classes.titleSec].join(' ')}>
              <h4>We Appreciate Your Interest!</h4>
              <p>Fill out the form below to ask a question or request a quote, and we will get back to you soon!</p>
            </div>
            <div className={[classes.fullSec, classes.formSec].join(' ')}>
              {quoteFrame}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default App;
