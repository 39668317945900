import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import classes from './Thanks.module.scss';


export default function Thanks() {
  return (
    <div className={[classes.fullSec, classes.Thanks].join(' ')}>
      <Helmet>
        <title>Thanks For Contacting Sweet Home Curb Appeal</title>
        <meta name="description" content="Thank you for your interest in Sweet Home Curb Appeal! We will be in touch with you soon." />
      </Helmet>

      <div className={[classes.fullSec].join(' ')}>
        <div className={classes.med2Def}>
          <h1>Thank you for your interest in Sweet Home Curb Appeal! We will be in touch with you soon.</h1>
          <p>Check out our <Link to="/gallery" className={classes.textLink}>project gallery</Link> to see
          some of our work, and get ideas for enhancing the curb appeal of your own sweet home.</p>
        </div>
      </div>
    </div>
  );
}