import React, {Component} from 'react';
// import { Link } from "react-router-dom";
import classes from "./Gallery.module.scss";
import Phone from "../../components/Shared/Phone";
import ColorChart from '../../images/gallery/ColorChart.jpg';
import AtlanticGrayCG from '../../images/gallery/AtlanticGrayCG.jpg';
import BarkTextureCoBRB from '../../images/gallery/BarkTextureCoBRB.jpg';
import CanyonBrownCG from '../../images/gallery/CanyonBrownCG.jpg';
import CurbJobs from '../../images/gallery/CurbJobs.jpg';
import DustyRoseCG1 from '../../images/gallery/DustyRoseCG1.jpg';
import DustyRoseCG3 from '../../images/gallery/DustyRoseCG3.jpg';
import DustyRoseCG4 from '../../images/gallery/DustyRoseCG4.jpg';
import DustyRoseCG5 from '../../images/gallery/DustyRoseCG5.jpg';
import GardenClaySG from '../../images/gallery/GardenClaySG.jpg';
import NaplesRed from '../../images/gallery/NaplesRed.jpg';
import PewterGrayCG from '../../images/gallery/PewterGrayCG.jpg';
import PrairieStoneCG from '../../images/gallery/PrairieStoneCG.jpg';
import PrairieStoneWB from '../../images/gallery/PrairieStoneWB.jpg';
import SaharaSandCG2 from '../../images/gallery/SaharaSandCG2.jpg';

import Berber1 from '../../images/gallery/Berber1.jpg';
import Berber2 from '../../images/gallery/Berber2.jpg';
import Stone from '../../images/gallery/Stone.jpg';
import Marrakesh1 from '../../images/gallery/Marrakesh1.jpg';
import Marrakesh2 from '../../images/gallery/Marrakesh2.jpg';
import MoroccanBg from '../../images/gallery/MoroccanBg.png';
import Rochester from '../../images/gallery/Rochester.jpg';
import TangierSlate1 from '../../images/gallery/TangierSlate1.png';
import TangierSlate2 from '../../images/gallery/TangierSlate2.png';

// Burnt Brick // Canyon Brown // Pewter Grey // Ochre Buff // Venetian Red // Garden Clay // Atlantic Grey // Cobble Buff // Dusty Rose // Sahara Sand // Sierra Slate // Straw Buff // Naples Red // Prairie Stone // Cocoa Brown // Earthy Tan


const galleryItems = [
  {id:'0', name:'Color Options', image:[ColorChart]},
  {id:'n1', name:'Berber', image:[Berber1]},
  {id:'n2', name:'Berber', image:[Berber2]},
  {id:'n3', name:'Stone', image:[Stone]},
  {id:'n4', name:'Marrakesh', image:[Marrakesh1]},
  {id:'n5', name:'Marrakesh', image:[Marrakesh2]},
  {id:'n6', name:'Moroccan Background', image:[MoroccanBg]},
  {id:'n7', name:'Rochester', image:[Rochester]},
  {id:'n8', name:'Tangier Slate', image:[TangierSlate1]},
  {id:'n9', name:'Tangier Slate', image:[TangierSlate2]},
  {id:'1', name:'Atlantic Grey, Charcoal Grey', image:[AtlanticGrayCG], color1:'Atlantic Grey, Charcoal Grey', color2:'CG'},
  {id:'2', name:'Cocoa Brown, Rustic Brown', image:[BarkTextureCoBRB], color1:'Cocoa Brown, Rustic Brown', color2:'RB'},
  {id:'3', name:'Canyon Brown, Charcoal Grey', image:[CanyonBrownCG], color1:'Canyon Brown, Charcoal Grey', color2:'CG'},
  {id:'4', name: 'Curb Job', image:[CurbJobs], color1:'', color2:''},
  {id:'5', name:'Dusty Rose, Charcoal Grey', image:[DustyRoseCG1], color1:'Dusty Rose, Charcoal Grey', color2:'CG'},
  {id:'6', name:'Dusty Rose, Charcoal Grey', image:[DustyRoseCG3], color1:'Dusty Rose, Charcoal Grey', color2:'CG'},
  {id:'7', name:'Dusty Rose, Charcoal Grey', image:[DustyRoseCG4], color1:'Dusty Rose, Charcoal Grey', color2:'CG'},
  {id:'8', name:'Dusty Rose, Charcoal Grey', image:[DustyRoseCG5], color1:'Dusty Rose, Charcoal Grey', color2:'CG'},
  {id:'9', name:'Garden Clay, Slate Grey', image:[GardenClaySG], color1:'Garden Clay, Slate Grey', color2:'SG'},
  {id:'10', name:'Naples Red', image:[NaplesRed], color1:'Naples Red'},
  {id:'11', name:'Pewter Grey, Charcoal Grey', image:[PewterGrayCG], color1:'Pewter Grey, Charcoal Grey', color2:'CG'},
  {id:'12', name:'Prairie Stone, Charcoal Grey', image:[PrairieStoneCG], color1:'Prairie Stone, Charcoal Grey', color2:'CG'},
  {id:'13', name:'Prairie Stone, Walnut Brown', image:[PrairieStoneWB], color1:'Prairie Stone, Walnut Brown', color2:'WB'},
  {id:'14', name:'Sahara Sand, Charcoal Grey', image:[SaharaSandCG2], color1:'Sahara Sand, Charcoal Grey', color2:'CG'},

];


const itemLoop = galleryItems.map((item) =>
  <li key={item.id} data-color1={item.color1} data-color2={item.color2} data-name={item.name} data-all="true" className={[classes.item].join(' ')}>
    <a href={item.image} data-caption={item.name}><img src={item.image} alt={item.name} /></a>
    <p className={classes.title}>{item.name}</p>
  </li>
);

class Gallery extends Component {
  render() {
    return (
      <div className={[classes.fullSec, classes.Gallery].join(' ')}>
        <div className={[classes.fullSec, classes.galleryHead].join(' ')}>
          <div className={[classes.med3Def].join(' ')}>
            <h1>Take a look at our project gallery below for examples of what Sweet Home Curb Appeal can do for you.</h1>
            <p><Phone callText="Call us today" />, or <button type="button" data-uk-toggle="target: #quote-modal" className={[classes.nostyle].join(' ')}>request a quote online</button> to 
            get started!</p>
          </div>
        </div>

        <div className={[classes.fullSec, classes.gallerySec].join(' ')}>
          <div className={[classes.inside].join(' ')}>
            {/* <div uk-filter="target: .js-filter"> */}
              {/* <ul className={[classes.fullSec, classes.optNav, "uk-subnavXXuk-subnav-pill"].join(' ')}>
                <li uk-filter-control="[data-all='true']"><button className={classes.nostyle}>All</button></li>
                <li uk-filter-control="[data-color1='Atlantic Grey']"><button className={classes.nostyle}>Atlantic Grey</button></li>
                <li uk-filter-control="[data-color1='Cocoa Brown']"><button className={classes.nostyle}>Cocoa Brown</button></li>
                <li uk-filter-control="[data-color1='Canyon Brown']"><button className={classes.nostyle}>Canyon Brown</button></li>
                <li uk-filter-control="[data-color1='Dusty Rose']"><button className={classes.nostyle}>Dusty Rose</button></li>
                <li uk-filter-control="[data-color1='Garden Clay']"><button className={classes.nostyle}>Garden Clay</button></li>
                <li uk-filter-control="[data-color1='Pewter Grey']"><button className={classes.nostyle}>Pewter Grey</button></li>
                <li uk-filter-control="[data-color1='Prairie Stone']"><button className={classes.nostyle}>Prairie Stone</button></li>
                <li uk-filter-control="[data-color1='Sahara Sand']"><button className={classes.nostyle}>Sahara Sand</button></li>
                <li uk-filter-control="[data-color1='Venetian Red']"><button className={classes.nostyle}>Venetian Red</button></li>
              </ul> */}
            <ul className={[classes.galleryUL, "js-filter uk-child-width-1-2 uk-child-width-1-3@l uk-child-width-1-2@m"].join(' ')} data-uk-grid="masonry: true"uk-lightbox="animation: slide">
              {itemLoop}
            </ul>
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }
}
export default Gallery;