import React from "react";
import classes from './Social.module.scss';
// import appStyles from "../../App.module.scss";

const social = (props) => {
  return (
    <ul className={[classes.Social, classes[props.where]].join(' ')}>
      <li><a href="https://www.facebook.com/profile.php?id=100093463051670" target="_blank" rel="noopener noreferrer">
        <span className={classes.SocialIcon} aria-label="Sweet Home Curb Appeal Facebook Link" uk-icon="icon: facebook"></span>
      </a></li>

       <li><a href="https://www.instagram.com/sweethomecurbappeal/" target="_blank" rel="noopener noreferrer">
        <span aria-label="Sweet Home Curb Appeal Instagram Link" uk-icon="icon: instagram"></span>
      </a></li>
    </ul>
  );
};

export default social;
