import React from 'react';
import Drawer from '@mui/material/Drawer';
import classes from "./Header.module.scss";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Phone from "../Shared/Phone";
import Email from "../Shared/Email";
import Social from "../Social/Social";

function TemporaryDrawer(props) {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={[classes.list, classes.SideNavList].join(' ')}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={classes.closeIcon}>X</div>
      <ul className={classes.menu}>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/gallery">Gallery</Link></li>
        <li><HashLink smooth to="/#details">Details</HashLink></li>
        <li><HashLink smooth to="/#about">About Us</HashLink></li>
        <li><button type="button" data-uk-toggle="target: #quote-modal">Get a Quote</button></li>
      </ul>

      <div className={[classes.fullSec, classes.drawerContact].join(' ')}>
        <hr/>
        <p className={[classes.dcItem, classes.dcEmail].join(' ')}><Email where="drawer" styleParent="mobileNav" /></p>
        <p className={classes.dcItem}><Phone where="drawer" styleParent="mobileNav" /></p>
        <p><Social where="drawer" styleParent="mobileNav" /></p>
      </div>
    </div>
  );

  return (
    <div className={[classes.NavDrawer].join(' ')}>
      <button className={classes.drawerButton} onClick={toggleDrawer('left', true)}>
        <span></span>
        <span></span>
        <span></span>
      </button>
     
        <Drawer anchor="left" open={state.left} onClose={toggleDrawer('left', false)} className={classes.cNavDrawer}>

        {sideList('left')}
      </Drawer>
    </div>
  );
}

export default TemporaryDrawer;