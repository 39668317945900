import React, {Component} from 'react';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import classes from './Home.module.scss';
import Phone from "../../components/Shared/Phone";
import stack1 from '../../images/MoroccanBg.png';
import stack2 from '../../images/Berber2.jpg';
import stack3 from '../../images/Stone.jpg';

const serveDesign = (
  <div className={classes.text}>
    <div className={classes.boxTitle}><p><span className={classes.main}>Custom Designs</span><br/> <span className={classes.stretch}>That Increase Your Curb Appeal</span></p></div>
    <p className={classes.body}>
      Our <span>unique and versatile borders</span> make a stunning addition to your landscape. Customize our curbing to fit your style, and blend with the natural 
      beauty surrounding your home. Choose from <span>various colors, patterns, and shapes,</span> and watch 
      our products transform your space.
    </p>
  </div>
);
const serveBene = (
  <div className={[classes.text, classes.t1].join(' ')}>
    <div className={classes.boxTitle}><p><span className={classes.main}>Amazing Benefits</span><br/> <span className={classes.stretch}>That Reduce Your Lawn Upkeep</span></p></div>
    <p className={classes.body}>
      Our <span>professional installations</span> make trimming around trees, garden beds, and driveways easier than you've ever dreamed! Our curbs <span>block 
      grass and weeds from growing</span> in unwanted areas, and act as a <span>root barrier</span>. Our borders even 
      help <span>direct water to flow</span> where you want it.
    </p>
  </div>
);
const serveInvest = (
  <div className={[classes.text, classes.t2].join(' ')}>
    <div className={classes.boxTitle}><p><span className={classes.main}>No Better Investment</span><br/> <span className={classes.stretch}>For Your Own Sweet Home</span></p></div>
    <p className={classes.body}>
      With our <span>high quality materials</span> and expert service, Sweet Home curbing is not only visually appealing, but <span>extremely 
      durable.</span> Our customers love that they see a <span>98.7% return on investment</span> with our affordable, long-lasting 
      borders, and save time and money on lawn maintenance.
    </p>
  </div>
);


const plaxSection = (
  <div id="about" className={[classes.fullSec, classes.plax1].join(' ')}>
    <div className={classes.medDef}>
      <h3>About Sweet Home Curb Appeal</h3>
      <div className={[classes.fullSec, classes.content].join(' ')}>
        <div className={[classes.hlf, classes.left].join(' ')}>
          <p>Sweet Home Curb Appeal is a Father & Son operation. We pride ourselves on having an eye for aesthetics and excellence. We have a mission to beautify Baldwin 
          County by helping enhance the natural beauty around homes in the area, and we work hard to achieve that mission. </p>

          <p>We love where we live, and our family believes in supporting one another and lending a hand. That's why Sweet Home Curb Appeal gives 10% back, either 
            within our community, or to aid people experiencing hurt and hardship throughout the world.
          </p>
        </div>

        <div className={[classes.hlf, classes.right].join(' ')}>
          <p>
          When life gets busy and chaotic, connecting with nature is one of the easiest ways to relax and restore our souls. Sweet Home Curb Appeal helps create a 
          captivating and peaceful outdoor space for you to do just that. We are grateful to God for His love and abundance of beauty!
          </p>

          <div className={classes.big}>
            <p>Contact us today for a quote, or for more information on what Sweet Home Curb Appeal can do for you!</p>
            <div className={classes.linkRow}>
              <Phone />
              <button type="button" data-uk-toggle="target: #quote-modal" className={[classes.nostyle].join(' ')}>Request a Quote</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const simpleIntro = (
  <div className={[classes.fullSec, classes.simpleIntro, classes.btmSkew, classes.skWhite].join(' ')}>
    <div className={[classes.med4Def, classes.wrap].join(' ')}>
      <div className={classes.text}>
        <h1>Decorative Concrete Edging to Enhance Your Outdoor Space</h1>
        <h2>Throughout Baldwin County, AL</h2>
        <h3>Add style and value to your home, while cutting down on the maintenance it requires!</h3>
        <div className={classes.linkRow}>
          <button type="button" data-uk-toggle="target: #quote-modal" className={[classes.shLink, classes.shLinkLg, classes.ilLink, ].join(' ')}>Get a Quote</button>
          <Link to="/gallery" className={[classes.shLink, classes.shLinkLg, classes.ilLink,  classes.xwhite].join(' ')}>Project Gallery</Link>
        </div>
      </div>
    </div>
  </div>
);

const plaxIntro = (
  <div className={[classes.fullSec, classes.plaxIntro].join(' ')} data-uk-parallax="bgy: 150; media:767">
    <div className={classes.medDef}>
    </div>
  </div>
);

const serveStackAlt = (
  <div id="details" className={[classes.fullSec, classes.serveStackC].join(' ')}>
    <div className={[classes.fullSec, classes.row1].join(' ')}>
      <div className={[classes.custWrap, classes.ssWrap1].join(' ')}>
        <div className={[classes.col, classes.col1a].join(' ')}>
          <div className={[classes.image, classes.imageTall].join(' ')}><img src={stack1} alt="PH DESC" /></div>
          {serveDesign}  
        </div>

        <div className={[classes.col, classes.col2a].join(' ')}>
          {serveBene}
         
          <div className={[classes.image, classes.imageSq].join(' ')}><img src={stack3} alt="PH DESC" /></div>
        </div>
      </div>
    </div>

    <div className={[classes.fullSec, classes.row2].join(' ')}>
      <div className={[classes.medDef, classes.ssWrap2].join(' ')}>
        <div className={[classes.image, classes.col, classes.col1b].join(' ')}><img src={stack2} alt="PH DESC" /></div>

        <div className={[classes.col, classes.col2b].join(' ')}> 
          {serveInvest}
        </div>
      </div>

      <div className={[classes.fullSec, classes.rowMob].join(' ')}>
        <div className={[classes.imageMob].join(' ')}><img src={stack2} alt="PH DESC" /></div>
        <div className={[classes.imageMob].join(' ')}><img src={stack3} alt="PH DESC" /></div>
      </div>
    </div>
  </div>
);

class Home extends Component {
  render() {
    return (
      <div className={[classes.fullSec, classes.Home].join(' ')}>
        <Helmet>
          <title>Decorative Concrete Edging in Baldwin County, AL | Sweet Home Curb Appeal</title>
          <meta name="description" content="Sweet Home Curb Appeal provides custom concrete edging throughout Baldwin County, Al. Enhance your yard, and increase your home's value with our beautiful, durable borders!" />
        </Helmet>

        {simpleIntro}
        {plaxIntro}
        <div className={[classes.fullSec, classes.banner].join(' ')}>
          <div className={[classes.medDef, classes.banWrap].join(' ')}>
            <div className={[classes.fullSec, classes.pullup].join(' ')}>
              <div className={[classes.col, classes.col1].join(' ')}>
                <h3>On a Mission to Beautify<br/> Baldwin County, AL</h3>
              </div>

              <div className={[classes.col, classes.mid3XX].join(' ')}>
                <p>Elberta · Robertsdale · Daphne · Orange Beach · <br/>Fairhope · Gulf Shores · Foley · Magnolia Springs</p>
                <div className={[classes.linkRow, classes.mobLink].join(' ')}><Link to="/gallery" className={[classes.shLink].join(' ')}>See Our Work</Link></div>
              </div>

              <div className={[classes.col, classes.col3].join(' ')}><Link to="/gallery" className={[classes.shLink].join(' ')}>See Our Work</Link></div>
            </div>
          </div>
        </div>

        {serveStackAlt}

        {plaxSection}

        <div className={[classes.fullSec, classes.insta].join(' ')}>
          <div className={[classes.insideXl, classes.iWrap].join(' ')}>
            <p className={classes.h3}>Follow Us</p> 
            <a href="https://www.instagram.com/sweethomecurbappeal/" target="_blank" rel="noopener noreferrer">@sweethomecurbappeal</a>

            <div className={[classes.fullSec, classes.feed].join(' ')}>
              <iframe
                title="Sweet Home Curb Appeal Instagram feed"
                src="https://cdn.lightwidget.com/widgets/b8ec144afd505aee84449c029cb2ade4.html"
                allowtransparency="true"
                className={[classes.instaWidge, "lightwidget-widget"].join(' ')}
              >
              </iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Home;