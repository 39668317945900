import React from "react";
// import { Link } from "react-router-dom";
import classes from "./Footer.module.scss";
// import NavItems from "../Header/NavItems";
// import Drawer from "../Header/Drawer";
// import Phone from "../Shared/Phone";
// import Email from "../Shared/Email";
// import Social from "../Social/Social";
// import logo from "../../theme/images/logo.png";

export default function Footer() {
  return (
    <footer className={[classes.fullSec, classes.footWrap].join(' ')}>
      <div className={classes.footCopy}>
        <div className={classes.insideAlt}>
          <div className={classes.fcInner}>
            <p className={classes.copy}>&copy; {(new Date().getFullYear())}, Sweet Home Curb Appeal. </p>
            <p className={classes.design}> Design by <a href="https://hsdesignhouse.com" target="_blank" rel="noreferrer">High Seas Design House.</a></p>
          </div>
        </div>
      </div>
    </footer>
  );
}
