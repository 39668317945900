import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import classes from "./Header.module.scss";
// import NavItems from "./NavItems";
import Drawer from "./Drawer";
import Phone from "../Shared/Phone";
import Email from "../Shared/Email";
import Social from "../Social/Social";
import logo from "../../images/hscaLogo.png";

class Header extends Component {
  render() {
    return (
      <div className={[classes.fullSec, classes.headerSec].join(' ')}>
        <div className={[classes.headContact].join(' ')}>
          <div className={classes.inside}>
          <ul>
            <li className={[classes.ilHd, classes.phone].join(' ')}><Phone where="topbar" /></li>
            <li className={[classes.ilHd, classes.email].join(' ')}><Email where="topbar" /></li>
            <li className={[classes.ilHd, classes.social].join(' ')}><Social where="topbar" /></li> 
          </ul>
          </div>
        </div>

        <div className={[classes.fullSec, classes.headWrap].join(' ')}>
          <header className={classes.header}>
            <div className={[classes.inside, classes.navRow].join(' ')}>
              <Link to="/" className={[classes.col, classes.headLogo].join(' ')}><img src={logo} alt="ALT"/></Link> 

              <ul className={[classes.col, classes.navSide].join(' ')}>
                <li className={[classes.navItem, classes.gallery].join(' ')}><Link to="/gallery">Gallery</Link></li>
                <li className={[classes.navItem, classes.dets].join(' ')}><HashLink smooth to="/#details">Details</HashLink></li>
                <li className={[classes.navItem, classes.about].join(' ')}><HashLink smooth to="/#about">About Us</HashLink></li>
                <li className={[classes.navItem, classes.quote].join(' ')}><button type="button" data-uk-toggle="target: #quote-modal">Get a Quote</button></li>
              </ul>

              <div className={[classes.mobNav, classes.ilAbs].join(' ')}><Drawer /></div>
            </div>
          </header>
        </div>
      </div>
    );
  }
}
export default Header;